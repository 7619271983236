import tipico from "../assets/svg/experience/tipico.svg";
import rb from "../assets/svg/experience/relevantBits.svg";
import kmBlue from "../assets/svg/experience/km-blue.svg";
import kmRed from "../assets/svg/experience/km-red.svg";
import mita from "../assets/svg/experience/mita.svg";
import b3w from "../assets/svg/experience/b3w.svg";

export const experienceData = [
    {
        id: "kingmakers-principal",
        company: "Kingmakers UK Services Ltd (London)",
        industry: "Customer Domain",
        teams: ["Acquisition", "Responsible Gaming", "Engagement", "Payments", "Wallet", "Native App", "Agency"],
        jobTitle: "Principal Frontend Engineer",
        startYear: "2024/04",
        endYear: "now",
        image: kmBlue,
    },
    {
        id: "kingmakers-senior",
        company: "Kingmakers UK Services Ltd (London)",
        industry: "Payments",
        jobTitle: "Senior Frontend Engineer II",
        startYear: "2023/09",
        endYear: "2024/04",
        image: kmRed,
    },
    {
        id: "relevant-bits-lead",
        company: "Relevant Bits Inc (London)",
        industry: "B2B Software Development",
        jobTitle: "Lead Software Engineer",
        startYear: "2022/09",
        endYear: "2023/09",
        image: rb,
    },
    {
        id: "tipico-software",
        company: "Tipico Co. Ltd, St. Julian’s (Malta)",
        industry: "iGaming/Sportsbook",
        jobTitle: "Software Developer",
        startYear: "2016",
        endYear: "2022",
        image: tipico,
    },
    {
        id: "b3w-software-engineer",
        company: "B3W Group - B3W Group, Gzira (Malta)",
        industry: "iGaming/Online Casino",
        jobTitle: "Software Developer",
        startYear: "2015",
        endYear: "2016",
        image: b3w,
    },
    {
        id: "b3w-frontend-engineer",
        company: "B3W Group - B3W Group, Gzira (Malta)",
        industry: "iGaming/Online Casino",
        jobTitle: "Front End Software Developer",
        startYear: "2013",
        endYear: "2015",
        image: b3w,
    },
    {
        id: "mita-web-deployment",
        company: "MITA, Santa Venera (Malta)",
        industry: "Digital Security Consultant for the Government of Malta",
        jobTitle: "Web Deployment and Quality Assurance",
        startYear: "2012",
        endYear: "2013",
        image: mita,
    },
];
