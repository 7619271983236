import React, { useContext, useState } from "react";
import Marquee from "react-fast-marquee";

import "./Skills.css";

import { ThemeContext } from "../../contexts/ThemeContext";
import {
    skillsBackendData,
    skillsFrontendData,
    skillsNativeData,
    skillsOperationalData,
    skillsToolsData,
} from "../../data/skillsData";
import { skillsImage } from "../../utils";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function Skills() {
    const { theme } = useContext(ThemeContext);
    const [isScroll, setIsScroll] = useState(true);

    const skillBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 30px ${theme.primary30}`,
    };
    const useStyles = makeStyles(() => ({
        toggleSkillsBtn: {
            position: "relative",
            backgroundColor: theme.primary,
            color: theme.secondary,
            borderRadius: "30px",
            textTransform: "inherit",
            textDecoration: "none",
            width: "240px",
            height: "50px",
            fontSize: "1rem",
            fontWeight: "500",
            fontFamily: "var(--primaryFont)",
            border: `3px solid ${theme.primary}`,
            transition: "100ms ease-out",
            marginBottom: "3rem",
            "&:hover span ": {
                color: "#785d28",
            },
            "&:hover": {
                backgroundColor: "#785d28",
                borderColor: "#785d28",
                color: "#785d28",
            },
        },
    }));

    const classes = useStyles();
    const skills = [skillsFrontendData, skillsBackendData, skillsToolsData, skillsOperationalData, skillsNativeData];

    return (
        <div id="skills" className="skills" style={{ backgroundColor: theme.secondary }}>
            <div className="skillsHeader">
                <h2 style={{ color: theme.primary }}>SKILLS</h2>
            </div>
            <Button
                className={classes.toggleSkillsBtn}
                style={{ backgroundColor: theme.secondary, color: theme.primary }}
                onClick={() => setIsScroll(!isScroll)}
            >
                <span style={{ backgroundColor: theme.primary }} className={`toggle ${isScroll ? "isScroll" : ""}`} />{" "}
                Toggle View
            </Button>
            {skills.map((skill) => (
                <React.Fragment key={`skill-${skill.title}`}>
                    <div className="skillsSubHeader">
                        <h2 style={{ color: theme.tertiary70 }}>{`${skill.title}`}</h2>
                        <span
                            style={{
                                color: theme.tertiary70,
                            }}
                            className="headerCounter"
                        >
                            {skill.list.length} skills
                        </span>
                    </div>

                    {isScroll ? (
                        <div className="skillsContainer">
                            <div className="skill--scroll">
                                <Marquee
                                    gradient={false}
                                    speed={10 * skill.list.length}
                                    pauseOnHover={true}
                                    pauseOnClick={true}
                                    delay={0}
                                    play={true}
                                    direction="left"
                                >
                                    {[...skill.list, ...skill.list, ...skill.list].map((skill, id) =>
                                        skillsImage(skill) ? (
                                            <div
                                                className="skill--box"
                                                key={`${skill.title}_${id}`}
                                                style={skillBoxStyle}
                                            >
                                                <img src={skillsImage(skill)} alt={skill} />
                                                <h3 style={{ color: theme.tertiary }}>{skill}</h3>
                                            </div>
                                        ) : null
                                    )}
                                </Marquee>
                            </div>
                        </div>
                    ) : (
                        <div className="skillsContainer list">
                            {skill.list.map((skill, id) => (
                                <div className="skill--box" key={`${skill.title}_${id}`} style={skillBoxStyle}>
                                    <img src={skillsImage(skill)} alt={skill} />
                                    <h3 style={{ color: theme.tertiary }}>{skill}</h3>
                                </div>
                            ))}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Skills;
