import philipSvg from "../assets/svg/about/code-typing-concept-illustration.svg";

export const aboutData = {
    title: "ABOUT",
    description: [
        "Hello, I'm Philip, a web engineer based in London, UK. With 10 years+ of experience in software engineering, I specialise in React frontend web development with Node.js backend. Currently, I hold the position of Principal Software Engineer at Kingmakers UK Services Ltd. where I am deeply passionate about creating performant, safe and engaging user experiences.",
        "Constantly driven by my love for software, I approach every project with a research-oriented mindset, constantly experimenting and iterating to find the best possible solutions. My relentless pursuit of scalability, high availability, and robustness has also led me to explore full stack development, including frontend, backend, networking, and tools like Jenkins, Docker, and Kubernetes.",
        "A skilled multi-tasker and quick learner with the ability to adapt seamlessly to new technologies and environments.",
        "I embrace an open and proactive approach to every task entrusted to me, leveraging my strong analytical and problem-solving skills to consistently deliver high-quality work. Additionally, my flexibility and creativity have contributed to taking my teams to new levels of performance.",
        "As part of a self-organised team, I work closely with product managers to develop mobile and desktop features. My ultimate goal is to continuously enhance our product, making it smarter, simpler, and safer for users.",
        "Throughout my career, I have honed my expertise in several technologies, including Javascript ES6, React, Redux, Typescript, Sass, and Jest, alongside various frontend libraries and programming languages. Furthermore, I actively support backend developers under pressure and tight deadlines by developing new features across the entire stack, from frontend to backend and OPS.",
        "Driven by my passion for this field, I also enjoy coaching and mentoring junior team members, firmly believing in the power of knowledge sharing and mutual learning.",
    ],
    image: philipSvg,
};
