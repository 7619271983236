import ukPin from "../assets/svg/currentStatus/ukPin.svg";
import homeOffice from "../assets/svg/currentStatus/homeoffice.svg";

export const currentStatusData = [
    {
        id: 2,
        name: "Employment Setup Preference",
        title: "Hybrid",
        text: "During the COVID19 pandemic I was introduced to remote working, to which I got very much accustomed to. It has made me aware of how important it is to leave reactions on Slack when a message is read, and how important it is to be precise and concise with written communication. I am a very sociable person and thus I enjoy going to the office once to two-times a week. This helps me connect and bond more with my team and colleagues.",
        image: homeOffice,
    },
    {
        id: 1,
        name: "Living",
        title: "Based in London (UK)",
        text: "I am currently based in London (UK), working as a Principal Software Engineer at Kingmakers UK Services Ltd. I have been living in London for the past 3 years and I am very much enjoying the city life. I am a very sociable person and I enjoy meeting new people and making new friends. I am also a very active person and I enjoy going to the gym, running, and going for long walks.",
        image: ukPin,
    },
];
