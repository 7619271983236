import { commonData } from "./commonData";

export const headerData = {
    name: "Philip Vella",
    title: "Principal Frontend Engineer",
    description: [
        "An agile thinker, who enjoys creating new features & modules, using different technologies & techniques. " +
            "Collaboration is the way I work, working closely with Product Management, sharing ideas on how & what to deliver to our customers. " +
            "My desire is to stay on top of the game, keeping up-to-date with the latest technologies pushing the product forward.",
    ],
    resumePdf: commonData.resumePdf,
};
